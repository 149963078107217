<template>
  <div :class="`${siteName}-fromThird-wrap`">
    <van-image class="logo" :src="require(`../assets/fromThird/${siteName}/third-logo.png`)" />
    <div class="money-box">
      <p class="money">{{payDataParams.amount}}</p>
      <p class="label">充值金额</p>
      <div class="order-time-box">
        <span class="tip">订单时效：</span>
        <span class="downtime">{{downTime}}</span>
      </div>
    </div>
    <div class="order-detail-box">
      <div class="channel">{{payDataParams.channel}}</div>
      <div class="order-num-box">
        <div class="label">订单编号：</div>
        <div class="num-box">
          <span>{{payDataParams.order_sn}}</span>
          <van-image class="copy" :src="require(`../assets/fromThird/${siteName}/copy-icon.png`)" @click="handleCopy(payDataParams.order_sn)" />
        </div>
      </div>
      <div class="order-num-box">
        <span>订单时间：</span>
        <span>{{payDataParams.order_time}}</span>
      </div>
    </div>
    <div class="balance-box">
      <span class="label">{{moneyName}}pay余额</span>
      <span class="balance">{{payDataParams.member_amount}}</span>
    </div>
    <div class="btn btn1" @click="showPayPasswordDialog=true">确认支付{{payDataParams.amount}}</div>
    <div class="btn btn2" @click="goUrl">获得更多{{moneyName}}币</div>
    <!-- 支付密码弹出框 -->
    <van-dialog v-model:show="showPayPasswordDialog" class="formThirdPay-dialog" title="支付密码" show-cancel-button :before-close="onBeforeClose">
        <van-field
            class="cancel-order-dialog-cell"
            v-model="payDataParams.pay_password"
            type="password"
            label="支付密码"
            placeholder="请输入支付密码"
            maxlength="6"
        />
    </van-dialog>
    <!-- 余额不足弹出框 -->
    <van-dialog v-model:show="showNoMoneyDialog" class="formThirdPay-dialog" title="温馨提示" show-cancel-button confirm-button-text="去充值" @cancel="showNoMoneyDialog=false" @confirm="goUrl">
        <p class="tips-content">您的余额不足，请充值</p>
    </van-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, onBeforeMount, computed} from 'vue'
import { fromThirdPay } from "@/api/api"
import useClipboard from 'vue-clipboard3'
import { Toast } from 'vant'
import { useRouter } from "vue-router"
import { useStore } from 'vuex'

const store = useStore()
const $router = useRouter()
// 盘口标识
const siteName = ref(siteChannel)
// 货币名字
const moneyName = computed(()=>{
  return siteName.value == 'agpay' ? 'AG' : siteName.value == 'qtpay' ? 'QT' : 'AI'
})
// 当前支付数据接口请求参数
const payDataParams = reactive({
  channel: '',
  three_username: '',
  actname: '',
  mcode: '',
  time: '',
  ip: '',
  order_sn: '',
  member_amount: '',
  order_time: '',
  password: '',
  pay_password: '',
  sign: '',
  amount: '',
  notifyUrl: ''
})
// 封装获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}
const curToken = ref('')
// 获取支付数据
const getPayData = () => {
  payDataParams.channel = getUrlParams(location.href)['channel']
  payDataParams.three_username = getUrlParams(location.href)['three_username']
  payDataParams.actname = getUrlParams(location.href)['actname']
  payDataParams.ip = getUrlParams(location.href)['ip']
  payDataParams.mcode = getUrlParams(location.href)['mcode']
  payDataParams.time = getUrlParams(location.href)['time']
  payDataParams.sign = getUrlParams(location.href)['sign']
  payDataParams.order_sn = getUrlParams(location.href)['order_sn']
  payDataParams.member_amount = getUrlParams(location.href)['member_amount']
  payDataParams.amount = getUrlParams(location.href)['amount']
  payDataParams.notifyUrl = getUrlParams(location.href)['notifyUrl']
  curToken.value = getUrlParams(location.href)['token']
  payDataParams.order_time = timestampToTime(Number(getUrlParams(location.href)['order_time']), true)
}
// 时间戳转换日期格式方法
function timestampToTime(timestamp, state = false) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) + '-';
    var D = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());
    var h = (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':';
    var m = (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':';
    var s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    return state ? Y + M + D + ' ' + h + m + s : Y + M + D;
}

// 倒计时时间
const downTime = ref('00:00')
// 定时器
let downTimer = null
// 倒计时函数
const timeDown = (time) => {
  clearInterval(downTimer)
  if(time<=0) return
    downTimer = setInterval(() => {
    time--
    if(time<=-1) {
        clearInterval(downTimer)
    } else {
        let second = Math.floor(time % 60);     // 计算秒 ，取余
        let minite = Math.floor((time / 60) % 60); //计算分 ，换算有多少分，取余，余出多少秒
        minite = minite.toString().length == 1 ? '0' + minite : minite;
        second = second.toString().length == 1 ? '0' + second : second;
        downTime.value = minite + ":" + second
    }
  },1000)
}
onBeforeMount(() => {
  getPayData()
})
onMounted(()=> {
  timeDown(getUrlParams(location.href)['order_time'])
})
// 跳转首页
const goUrl = () => {
  store.dispatch("saveLoginInfosActions", {token:curToken.value});
  $router.push('/home')
  localStorage.setItem('isFromThird', true)
  let urlStr = window.location.href.split('?')[1]
  sessionStorage.setItem('fromThirdUrlStr', urlStr)
}
// 复制
const { toClipboard } = useClipboard()
const handleCopy = async(content) => {
    try {
        await toClipboard(content)
        Toast.success('复制成功')
    } catch (e) {
        console.error(e)
    }
}
// 是否显示支付密码框
const showPayPasswordDialog = ref(false)
// 取消默认关闭弹框事件
const onBeforeClose = (action) => {
    // 点击了确定按钮
    if (action === "confirm") {
        if (!payDataParams.pay_password) {
            return Toast.fail('请输入支付密码')
        } else {
            btnClock.value = true
            delete payDataParams.member_amount
            delete payDataParams.password
            delete payDataParams.order_time
            fromThirdPay(payDataParams).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    showPayPasswordDialog.value = false
                    payDataParams.pay_password = ''
                    Toast.success('支付成功')
                } else if (res.result == 100028) {
                  showNoMoneyDialog.value = true
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
    }
    // 点击了取消按钮
    else {
        showPayPasswordDialog.value = false
        payDataParams.pay_password = ''
        btnClock.value = false
    }
}
// 按钮锁
const btnClock = ref(false)
// 余额不足提示框
const showNoMoneyDialog = ref(false)
</script>

<style lang="less" scoped>
.agpay-fromThird-wrap {
  min-height: 100vh;
  padding: 20px 16px;
  background: #313131;
  .logo {
    display: block;
    width: 88px;
    height: 88px;
    margin: 0 auto 20px;
  }
  .money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .money {
      font-family: 'DIN';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #E1CAAD;
    }
    .label {
      margin-bottom: 15px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: rgba(255, 255, 255, 0.6);
    }
    .order-time-box {
      display: flex;
      align-items: center;
      .tip {
        font-family: 'PingFang SC';
        color: #FFFFFF;
      }
      .downtime {
        padding-top: 2.5px;
        font-family: 'DIN';
        font-style: normal;
        font-weight: 700;
        color: #D44131;
      }
    }
  }
  .order-detail-box {
    margin-top: 20px;
    padding: 20px 16px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    .channel {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }
    .order-num-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      color: #E1CAAD;
      .num-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy {
          width: 24px;
          height: 24px;
          margin-left: 8.5px;
        }
      }
    }
  }
  .balance-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 20px 16px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    color: #E1CAAD;
    .label {
      margin-right: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }
    .balance {
      padding-top: 2px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .btn {
    height: 44px;
    margin-top: 20px;
    line-height: 44px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    &.btn1 {
      background: linear-gradient(360deg, #AA8B68 0%, #E1CAAD 108.75%);
    }
    &.btn2 {
      background: linear-gradient(180deg, #FF8072 0%, #D44131 100%);
    }
  }
  :deep(.formThirdPay-dialog) {
    background: #fff;
    .van-dialog__header {
      color: #946837;
      padding: 15px 0;
      font-weight: 600;
      font-size: 16px;
    }
    .tips-content {
      padding: 30px 0;
      color: #946837;
      text-align: center;
    }
    .van-cell {
      background: inherit;
    }
    .van-cell__title {
      color: #946837;
    }
    .van-field__control {
      color: #535c66;
    }
    .van-dialog__cancel {
      background: #E1CAAD;
    }
    .van-dialog__confirm {
      background: linear-gradient(360deg, #AA8B68 0%, #E1CAAD 108.75%);
    }
  }
}
.aipay-fromThird-wrap {
  height: 100vh;
  padding: 20px 16px;
  background: #F8F9FA;
  .logo {
    display: block;
    width: 76px;
    height: 108px;
    margin: 0 auto 20px;
  }
  .money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .money {
      font-family: 'DIN';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #535C66;
    }
    .label {
      margin-bottom: 15px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #535C66;
    }
    .order-time-box {
      display: flex;
      align-items: center;
      .tip {
        font-family: 'PingFang SC';
        color: #535C66;
      }
      .downtime {
        padding-top: 2.5px;
        font-family: 'DIN';
        font-style: normal;
        font-weight: 700;
        color: #D44131;
      }
    }
  }
  .order-detail-box {
    margin-top: 20px;
    padding: 20px 16px;
    background: #fff;
    border-radius: 8px;
    .channel {
      font-size: 16px;
      font-weight: 500;
      color: #02318B;
    }
    .order-num-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      color: #535C66;
      .num-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy {
          width: 24px;
          height: 24px;
          margin-left: 8.5px;
        }
      }
    }
  }
  .balance-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 20px 16px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    .label {
      margin-right: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #02318B;
    }
    .balance {
      padding-top: 2px;
      font-size: 20px;
      font-weight: 600;
      color: #535C66;
    }
  }
  .btn {
    height: 44px;
    margin-top: 20px;
    line-height: 44px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    &.btn1 {
      background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
    }
    &.btn2 {
      background: #EBF2FF;
      color: #02318B;
    }
  }
  :deep(.formThirdPay-dialog) {
    background: #fff;
    .van-dialog__header {
      color: #02318B;
      padding: 15px 0;
      font-weight: 600;
      font-size: 16px;
    }
    .tips-content {
      padding: 30px 0;
      color: #02318B;
      text-align: center;
    }
    .van-cell {
      background: inherit;
    }
    .van-cell__title {
      color: #02318B;
    }
    .van-field__control {
      color: #535c66;
    }
    .van-dialog__cancel {
      background: #EBF2FF;
      color: #02318B;
    }
    .van-dialog__confirm {
      background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
    }
  }
}
.qtpay-fromThird-wrap {
  height: 100vh;
  padding: 20px 16px;
  background: #F8F9FA;
  .logo {
    display: block;
    width: 76px;
    height: 76px;
    margin: 0 auto 20px;
  }
  .money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .money {
      font-family: 'DIN';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #535C66;
    }
    .label {
      margin-bottom: 15px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #535C66;
    }
    .order-time-box {
      display: flex;
      align-items: center;
      .tip {
        font-family: 'PingFang SC';
        color: #535C66;
      }
      .downtime {
        padding-top: 2.5px;
        font-family: 'DIN';
        font-style: normal;
        font-weight: 700;
        color: #D44131;
      }
    }
  }
  .order-detail-box {
    margin-top: 20px;
    padding: 20px 16px;
    background: #fff;
    border-radius: 8px;
    .channel {
      font-size: 16px;
      font-weight: 500;
      color: #02318B;
    }
    .order-num-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      color: #535C66;
      .num-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy {
          width: 24px;
          height: 24px;
          margin-left: 8.5px;
        }
      }
    }
  }
  .balance-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 20px 16px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    .label {
      margin-right: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #02318B;
    }
    .balance {
      padding-top: 2px;
      font-size: 20px;
      font-weight: 600;
      color: #535C66;
    }
  }
  .btn {
    height: 44px;
    margin-top: 20px;
    line-height: 44px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    &.btn1 {
      background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
    }
    &.btn2 {
      background: #EBF2FF;
      color: #02318B;
    }
  }
  :deep(.formThirdPay-dialog) {
    background: #fff;
    .van-dialog__header {
      color: #02318B;
      padding: 15px 0;
      font-weight: 600;
      font-size: 16px;
    }
    .tips-content {
      padding: 30px 0;
      color: #02318B;
      text-align: center;
    }
    .van-cell {
      background: inherit;
    }
    .van-cell__title {
      color: #02318B;
    }
    .van-field__control {
      color: #535c66;
    }
    .van-dialog__cancel {
      background: #EBF2FF;
      color: #02318B;
    }
    .van-dialog__confirm {
      background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
    }
  }
}
</style>
