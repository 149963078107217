<template>
  <div :class="`${siteName}-fromThird-wrap`">
    <van-nav-bar
        title="初次使用设置"
        :border="false"
    />
    <div class="main-wrap">
      <h3>设置账号</h3>
      <van-field v-model="payDataParams.three_username" readonly disabled :border="false" :left-icon="require(`../assets/fromThird/${siteName}/icon_account_default.png`)" placeholder="首字母开头-6-20位" autocomplete="off" />
      <h3>真实姓名</h3>
      <van-field v-model="payDataParams.actname" readonly disabled :border="false" :left-icon="require(`../assets/fromThird/${siteName}/icon_account_default.png`)" placeholder="输入真实姓名" autocomplete="off" />
      <h3>设置登录密码</h3>
      <van-field v-model="payDataParams.password" type="password" :border="false" :left-icon="require(`../assets/fromThird/${siteName}/icon_password_default.png`)" placeholder="设置登录密码" clearable autocomplete="off" />
      <h3>设置支付密码</h3>
      <van-field v-model="payDataParams.pay_password" type="password" :border="false" :left-icon="require(`../assets/fromThird/${siteName}/icon_password_default.png`)" placeholder="6位数字支付密码" clearable autocomplete="off" maxlength="6" />
      <div class="btn" @click="handleReg">确认</div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onBeforeMount, computed} from 'vue'
import { fromThirdReg } from "@/api/api"
import { Toast } from 'vant'
import { useRouter } from "vue-router"
import { useStore } from "vuex"

const $router = useRouter();
const store = useStore();
const siteName = ref(siteChannel)
// 当前支付数据接口请求参数
const payDataParams = reactive({
  channel: '',
  three_username: '',
  actname: '',
  mcode: '',
  time: '',
  ip: '',
  order_sn: '',
  member_amount: '',
  order_time: '',
  password: '',
  pay_password: '',
  sign: '',
  amount: '',
  notifyUrl: ''
})
// 封装获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}
// 获取支付数据
const getPayData = () => {
  payDataParams.channel = getUrlParams(location.href)['channel']
  payDataParams.three_username = getUrlParams(location.href)['three_username']
  payDataParams.actname = getUrlParams(location.href)['actname']
  payDataParams.ip = getUrlParams(location.href)['ip']
  payDataParams.mcode = getUrlParams(location.href)['mcode']
  payDataParams.time = getUrlParams(location.href)['time']
  payDataParams.sign = getUrlParams(location.href)['sign']
  payDataParams.order_sn = getUrlParams(location.href)['order_sn']
  payDataParams.member_amount = getUrlParams(location.href)['member_amount']
  payDataParams.amount = getUrlParams(location.href)['amount']
  payDataParams.notifyUrl = getUrlParams(location.href)['notifyUrl']
  payDataParams.order_time = getUrlParams(location.href)['order_time']
}
onBeforeMount(() => {
  getPayData()
})
const handleReg = () => {
  if(!payDataParams.password) {
      return Toast.fail('请设置登录密码')
  }
  if(!payDataParams.pay_password) {
      return Toast.fail('请设置支付密码')
  }
  fromThirdReg(payDataParams).then(res => {
      if(res.result == 200000) {
          Toast.success("设置成功");
          store.dispatch("saveLoginInfosActions", res.data)
          $router.push("/home")
      } else {
          Toast.fail(res.msg)
      }
  })
}
</script>

<style lang="less" scoped>
.agpay-fromThird-wrap {
  min-height: 100vh;
  background: #313131;
  :deep(.van-nav-bar) {
    background: #020202;
    .van-nav-bar__title {
      color: #fff;
    }
  }
  .main-wrap {
    padding: 0 16px 16px;
    h3 {
      padding: 16px 0 8px;
    }
    :deep(.van-cell) {
      background: rgba(255, 255, 255, 0.15);
      border-radius: 6px;
      .van-field__left-icon {
        .van-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }
      .van-field__control {
        color: #fff;
      }
      input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .btn {
      height: 44px;
      margin-top: 24px;
      line-height: 44px;
      background: linear-gradient(360deg, #AA8B68 0%, #E1CAAD 108.75%);
      border-radius: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.aipay-fromThird-wrap {
  height: 100vh;
  background: #fff;
  .main-wrap {
    padding: 0 16px 16px;
    h3 {
      padding: 16px 0 8px;
      color: #535C66;
    }
    :deep(.van-cell) {
      background: #EBF2FF;
      border-radius: 6px;
      .van-field__left-icon {
        .van-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }
      .van-field__control {
        color: #535C66;
      }
      input::-webkit-input-placeholder {
        color: rgba(83, 92, 102, 0.5);
      }
    }
    .btn {
      height: 44px;
      margin-top: 24px;
      line-height: 44px;
      background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
      border-radius: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.qtpay-fromThird-wrap {
  height: 100vh;
  background: #fff;
  .main-wrap {
    padding: 0 16px 16px;
    h3 {
      padding: 16px 0 8px;
      color: #535C66;
    }
    :deep(.van-cell) {
      background: #EBF2FF;
      border-radius: 6px;
      .van-field__left-icon {
        .van-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }
      .van-field__control {
        color: #535C66;
      }
      input::-webkit-input-placeholder {
        color: rgba(83, 92, 102, 0.5);
      }
    }
    .btn {
      height: 44px;
      margin-top: 24px;
      line-height: 44px;
      background: linear-gradient(180deg, #144CB8 0%, #02318B 100%);
      border-radius: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>